<template>
  <div class="has-nav-bar view-inspection">
    <van-nav-bar title="巡检记录" fixed left-arrow @click-left="onClickLeft">
    </van-nav-bar>
    <div>
      <van-search
        v-model="query.name"
        placeholder="请输入巡检名称关键字"
        class="search-bar"
        @search="onConfirm"
        @clear="onConfirm"
      >
        <template #left-icon>
          <van-icon name="sousuo" class-prefix="iconfont" class="search-icon" />
        </template>
      </van-search>

      <date-time-picker-popup
        v-model="query.time"
        type="date"
        :time-close="false"
        @confirm="timeConfirm"
        @clear="onConfirm"
      ></date-time-picker-popup>
      <div class="view-inspection__header-feature">
        <span class="view-inspection__header-feature--left">
          共 {{ total }} 个巡检记录
        </span>
        <van-icon
          class-prefix="iconfont"
          name="guolv"
          class="view-inspection__header-feature--right"
          @click="searchuserVisible = true"
        />
      </div>
      <van-pull-refresh
        v-model="isPullRefreshing"
        class="view-inspection__list"
        @refresh="onRefresh"
      >
        <van-list
          v-model="isLoading"
          :finished="isFinished"
          :finished-text="isError ? '' : '没有更多了'"
          :error.sync="isError"
          error-text="加载失败，请稍后再试！"
          @load="getList"
        >
          <van-cell
            v-for="(item, idx) in list"
            :key="idx"
            class="view-inspection__rows"
            is-link
            @click="gotoDetails(item)"
          >
            <div class="view-inspection__rows-left">
              <div class="view-inspection__rows-top">
                <p>{{ item.name }}</p>
              </div>
              <div class="view-inspection__rows-bottom">
                <p>
                  <span>
                    <van-icon
                      class="view-inspection__rows-timeicon"
                      class-prefix="iconfont"
                      name="jigou"
                    />
                    <b>{{ item.departmentName }}</b>
                  </span>
                  <span>
                    <van-icon
                      class="view-inspection__rows-timeicon view-inspection__rows-people"
                      class-prefix="iconfont"
                      name="icomember"
                    />
                    <b>{{ item.peopleName }}</b>
                  </span>
                </p>
                <p>
                  <span>
                    <van-icon
                      class="view-inspection__rows-timeicon"
                      class-prefix="iconfont"
                      name="ico-timerange"
                    />
                    <b>{{ item.time }}</b>
                  </span>
                  <span>
                    <b>{{ item.shift }}</b>
                  </span>
                </p>
              </div>
            </div>
          </van-cell>
        </van-list>
      </van-pull-refresh>
    </div>
    <cascader-tree
      :visible="treeVisible"
      title="所属部门"
      :data="treeData"
      :choosed-val="query.department"
      @close="handlerTreeClose"
    />
    <auto-input
      :visible="userVisible"
      title="选择人员"
      :data="userData"
      :choosed-val="query.peopleList"
      @close="handlerUserClose"
    />
    <van-popup
      v-model="searchuserVisible"
      position="bottom"
      class="filter-popup"
      :duration="duration"
    >
      <div class="popup-content">
        <van-field
          readonly
          clickable
          name="deptCode"
          :value="query.departmentName"
          label="请选择巡检部门"
          :rules="[{ message: '请选择所属部门' }]"
          @click="handlerTreeClick($event, 'deptCode')"
        />
        <van-field
          readonly
          clickable
          name="leader"
          :value="query.peopleListName"
          label="请选择巡检人"
          :rules="[{ message: '请选择负责人' }]"
          @click="handlerUsersClick($event, 'leader')"
        />
      </div>
      <div class="popup-footer">
        <van-button type="danger" class="reset-btn" @click="onReset"
          >重置</van-button
        >
        <van-button type="info" class="handle-btn" @click="onConfirm"
          >确定</van-button
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import {
  NavBar,
  Cell,
  List,
  SwipeCell,
  Button,
  PullRefresh,
  Popup,
  Icon,
  Search,
  Field,
  DatetimePicker
} from "vant";
import { mapActions, mapState } from "vuex";
import authorization from "../../api/authorization";
import { getInspectionList } from "../../api/psm";
import DateTimePickerPopup from "../../components/DatetimePickerPopup";
import dayjs from "dayjs";

export default {
  name: "UserMessage",
  components: {
    DateTimePickerPopup,
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [SwipeCell.name]: SwipeCell,
    [Button.name]: Button,
    [PullRefresh.name]: PullRefresh,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    [Search.name]: Search,
    [Field.name]: Field,
    [DatetimePicker.name]: DatetimePicker,
    [List.name]: List
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo
    })
  },
  data() {
    return {
      query: {
        department: "",
        departmentName: "",
        peopleListName: "",
        peopleList: "",
        time: "",
        page: 1,
        size: 10,
        typeFlag: 1
      },
      searchuserVisible: false,
      total: 0,
      list: [],
      info: {},
      treeValue: "",
      treeName: "",
      treeVisible: false,
      treeData: [],
      userData: [],
      leaderTypes: [],
      userVisible: false,
      popupValue: "",
      isPullRefreshing: false,
      isLoading: false,
      isFinished: false,
      isError: false,
      duration: 0.3
    };
  },
  watch: {},
  created() {
    // this.getDeptTree(this.userInfo.orgCode);
    this.query.time = dayjs().format("YYYY-MM-DD");
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    // 选择部门
    handlerTreeClick(ev, key) {
      this.duration = 0;
      this.searchuserVisible = false;
      authorization.getSelectTreeDept(this.userInfo.orgCode).then(res => {
        this.treeVisible = true;
        this.treeData = res;
      });
    },
    // 关闭部门
    handlerTreeClose(row) {
      if (row) {
        this.query.department = row.id;
        this.query.departmentName = row.label;

        // 清空已选择人
        this.query.peopleList = "";
        this.query.peopleListName = "";

        this.getListUsersData(this.query.department);
      }
      this.treeVisible = false;
      this.duration = 0.3;
      this.searchuserVisible = true;
    },
    // 选择人
    handlerUsersClick(ev, key) {
      this.duration = 0;
      this.searchuserVisible = false;
      this.userVisible = true;
      this.popupValue = this.info[key];
    },
    // 关闭选择人
    handlerUserClose(row) {
      if (row) {
        this.query.peopleList = row.value;
        this.query.peopleListName = row.label;
      }
      this.userVisible = false;
      this.duration = 0.3;
      this.searchuserVisible = true;
    },
    // 筛选重置
    onReset() {
      this.query.department = "";
      this.query.departmentName = "";
      this.query.peopleList = "";
      this.query.peopleListName = "";
    },
    // 筛选确认
    onConfirm() {
      // 查询数据
      this.searchuserVisible = false;
      this.query.page = 1;
      this.list = [];
      this.getList();
    },
    getListUsersData(code) {
      authorization
        .getListUsers(code)
        .then(res => {
          this.userData = res.map(item => ({
            label: item.nickName,
            value: item.id
          }));
        })
        .catch(err => {});
    },
    gotoDetails(row) {
      this.$router.push({
        path: "/inspection/" + row.id
      });
    },
    async getList() {
      this.isFinished = false;
      this.isLoading = true;
      if (this.isPullRefreshing) {
        this.isPullRefreshing = false;
      }
      try {
        // 查询前去掉参数
        const { list, total } = await getInspectionList(this.query);
        this.total = total;
        this.isError = false;
        this.isLoading = false;
        if (list && list.length) {
          this.list = this.list.concat(list);
          this.query.page++;
        } else {
          this.isFinished = true;
        }
      } catch (error) {
        this.isLoading = false;
        this.isFinished = true;
        this.isError = true;
      }
    },
    onRefresh() {
      this.query.page = 1;
      this.list = [];
      this.getList();
    },
    timeConfirm() {
      this.query.page = 1;
      this.list = [];
      this.getList();
    }
  }
};
</script>
<style lang="scss">
.view-inspection {
  &__list {
    height: calc(100vh - 58px - 165px);
    overflow: auto;
  }
  &__nav-right--icons {
    font-size: 15px;
  }
  &__header {
    height: 165px;
    margin-bottom: 12px;
  }
  &__header-feature {
    display: flex;
    justify-content: space-between;
    margin: 0 16px;
    &--left {
      color: $--color-gray-list-page;
    }
    &--right {
      color: $--color-icon;
    }
  }
  &__rows {
    align-items: center;
    .van-cell__value {
      color: $--color-gray-list-page;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 1px;
    }
    &-left {
      width: 80vw;
      p {
        span {
          &:after {
            content: "|";
            display: inline-block;
            margin: 0 10px;
            font-size: 16px;
          }
          &:last-of-type {
            &:after {
              display: none;
            }
          }
        }
      }
    }
    &-top {
      color: $--color-black-list-page;
      margin-bottom: 8px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      p {
        font-size: 14px;
      }
      span {
        font-size: 13px;
        color: $--color-gray-list-page;
      }
    }
    &-timeicon {
      font-size: 13px;
      margin-right: 5px;
    }
    &-people {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &-icon {
      font-size: 42px;
      line-height: 42px;
    }
    &-bottom {
      font-size: 13px;
      p {
        margin: 6px 0;
        margin-bottom: 6px;
        line-height: 1.1;
        &:last-child {
          margin: 0;
        }
      }
    }
  }
}
</style>
